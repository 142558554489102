import { makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { VFC } from "react";
import { useTrialExpiration } from "../hooks/atoms/useTrialExpiration";
import { SubscriptionStatus } from "../reclaim-api/team/Team";
import { pluralize } from "../utils/strings";
import { Banner } from "./Banner";
import { Link } from "./Link";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    together: {
      marginLeft: theme.spacing(0.5),
      whiteSpace: "nowrap",
    },
  }),
  {
    classNamePrefix: "TrialExpirationBanner",
  }
);

export type TrialExpirationBannerJSSClassKey = keyof ReturnType<typeof useStyles>;

export type TrialExpirationBannerProps = {
  classes?: Partial<ClassNameMap<TrialExpirationBannerJSSClassKey>>;
  className?: string;
};

export const TrialExpirationBanner: VFC<TrialExpirationBannerProps> = ({ className, classes: extClasses }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const { subscriptionStatus, daysLeftInTrial, upsell } = useTrialExpiration();

  return (
    <Banner className={clsx(classes.root, className)} in={!!daysLeftInTrial && subscriptionStatus !== SubscriptionStatus.Active}>
      {!!daysLeftInTrial && daysLeftInTrial > 0 ? (
        <>
          Just
          <strong>
            {daysLeftInTrial} {pluralize(daysLeftInTrial || 0, "day")} left
          </strong>
          with access to {upsell} -{" "}
        </>
      ) : (
        "Your trial has ended - "
      )}
      <Link className={classes.together} href="/billing">
        Upgrade before March 28th to save 20% on your first&nbsp;year
      </Link>
    </Banner>
  );
};
